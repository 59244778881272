/* typography styles */
.heading {
    font-weight: 700;
    font-size: 1.62rem;
    line-height: 1.02rem;
}

.subheading {
    font-weight: 700;
    font-size: 1.02rem;
    line-height: 1.02rem;
}

.subSubheading{
    font-weight: 700;
    font-size: 0.82rem;
    line-height: 1.02rem;
}

.body {
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 2.03rem;
}

.caption {
    font-weight: 400;
    font-size: 0.66rem;
    line-height: 1.02rem;
}

.mainContainer {
    background-color: #F6F7FF;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
}

.wrapper {
    background-color: #F6F7FF;
    flex: 1;
    display: flex;
    flex-flow: column;
    row-gap: 0.75rem;
    padding: 2vh 3vw
}


/* other styles*/ 
.header {
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    padding: 1.25vh 2vw;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
}

@media only screen and (min-width: 101em) {
    .wrapper {
        row-gap: 3.5vh;
    }
    .header {
        margin-bottom: 2.5vh;
    }
  }

.topContainer {
    box-shadow: 0px 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3vh 3.5vw;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #DADADA;
}

.bottomContainer {
    box-shadow: 0px 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5.1vh 4.5vw;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #DADADA;
}

.downloadInfoContainer {
    display: flex;
    align-items: center;
    column-gap: 2.25rem;
}

.trentialMobileImage {
    height: auto;
    width: 5.25rem
}

.walletDiv {
    display: flex;
    align-items: center;
    column-gap: 1.65rem;
}

.walletImage {
    height: auto;
    width: 8rem
}

.downloadTextGroup {
    width: 24vw
}

.stores {
    display: flex;
    column-gap: 2vw;
}

.storeImage {
    height: auto;
    width: 7.2rem
}

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    height: 2.8rem;
}

.qrContainer {
    padding: 3vh 0;
}

@media only screen and (min-width: 101em) {
    .footer {
        height: auto
    }
  }

.scanIcon {
    height: auto;
    width: 1.3rem;
    vertical-align: middle;
    margin: 0 0.2rem;
}  

.trentialLogo {
    height: auto;
    width: 5.2rem
}

.trentialBlackLogo {
    height: auto;
    width: 4.6rem
}